<template>
  <keep-alive>
    <router-view v-if="$route.meta.subPage"></router-view>
    <a-card :bordered="false" v-else>
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="GLOBAL.queryRowGutter">
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="订单号">
                <a-input v-model.trim="queryParam.order_no" placeholder="按照订单号搜索"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="业务时间">
                <a-range-picker @change="businessDateChange"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="状态">
                <a-select
                  v-model="queryParam.status"
                  allowClear
                  placeholder="请选择状态"
                  style="width: 100%"
                  @change="queryList"
                >
                  <a-select-option v-for="op in statusData" :key="op.value">
                    {{ op.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="EP号">
                <a-input v-model.trim="queryParam.ep_no" placeholder="按照EP号搜索"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="JOB号">
                <a-input v-model.trim="queryParam.job_no" placeholder="按照JOB号搜索"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="拖运编号">
                <a-input v-model.trim="queryParam.business_no" placeholder="按照拖运编号搜索"/>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="调度人">
                <a-select
                  v-model="queryParam.dispatch_name"
                  allowClear
                  placeholder="按照调度人搜索"
                  style="width: 100%"
                  @change="queryList"
                >
                  <a-select-option v-for="op in dispatchNameData" :key="op.value">
                    {{ op.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="客户">
                <a-select
                  v-model="queryParam.customer_id"
                  allowClear
                  placeholder="请选择客户"
                  style="width: 100%"
                  @change="queryList"
                >
                  <a-select-option v-for="op in customerData" :key="op.customer_id">
                    {{ op.nick_name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col v-bind="GLOBAL.queryColSpan">
              <a-form-item label="委托车队">
                <a-select
                  v-model="queryParam.supplier_id"
                  allowClear
                  placeholder="请选择委托车队"
                  style="width: 100%"
                  @change="queryList"
                >
                  <a-select-option v-for="op in supplierData" :key="op.customer_id">
                    {{ op.nick_name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-row :gutter="24">
          <a-col :span="16">
            <a-button type="query" icon="search" @click="queryList">查询</a-button>
            <a-button type="primary" icon="plus" @click="handlerCreate">新建</a-button>
            <a-button type="danger" icon="delete" :disabled="!selectedRowKeys.length" @click="batchDelete('all')">删除</a-button>
            <a-button type="primary" :disabled="!selectedRowKeys.length" @click="confirmConShipmentStatus">完成拖运</a-button>
            <a-button type="primary" :disabled="!selectedRowKeys.length" @click="cancelConShipmentStatus">取消拖运</a-button>
            <a-button type="primary" icon="download" @click="exportSheet">导出</a-button>
            <a-button type="primary" icon="download" @click="downloadTemplate">下载托运订单模板</a-button>
            <a-upload
              :beforeUpload="beforeUpload"
              :customRequest="handleUpload"
              :showUploadList="false"
            >
              <a-button type="primary" icon="upload">导入托运订单模板</a-button>
            </a-upload>
          </a-col>
          <a-col :span="8">
            <a-popover placement="bottomRight">
              <template slot="content" v-if="noChargeOrPayOrderData.length > 0">
                <p v-for="(op, index) in noChargeOrPayOrderData" :key="index">
                  <span style="color: green; font-size: 14px;">{{ op.serial_num }}</span> -
                  <span style="color: orange; font-size: 14px;" v-if="op.no_charge_msg">{{ op.no_charge_msg }} / </span>
                  <span style="color: red; font-size: 14px;" v-if="op.no_pay_msg">{{ op.no_pay_msg }}</span>
                </p>
              </template>
              <template slot="content" v-else>
                <span>暂无</span>
              </template>
              <template slot="title">
                <span>未收未付订单</span>
              </template>
              <a-button :type="noChargeOrPayOrderData.length > 0 ? 'danger' : null" icon="alert" style="margin-left: 100px;">
                未收未付订单{{ noChargeOrPayOrderData.length }}条
              </a-button>
            </a-popover>
            <a-popover placement="bottomRight">
              <template slot="content" v-if="noFinishOrderData.length > 0">
                <p v-for="(op, index) in noFinishOrderData" :key="index">
                  订单号：<span style="color: red; font-size: 14px;">{{ op.serial_num }}</span>
                  截港时间：<span style="color: red; font-size: 14px;">{{ op.discharge_port_date }}</span>
                </p>
              </template>
              <template slot="content" v-else>
                <span>暂无</span>
              </template>
              <template slot="title">
                <span>一周前截港时间到期还完成计划订单</span>
              </template>
              <a-button :type="noFinishOrderData.length > 0 ? 'danger' : null" icon="alert">
                截港时间未完成订单{{ noFinishOrderData.length }}条
              </a-button>
            </a-popover>
          </a-col>
        </a-row>
      </div>

      <a-alert :message="alertMsg" type="info" show-icon />
      <a-alert :message="boxMsg" type="info" show-icon style="margin: 10px 0px;"/>

      <a-table
        size="small"
        row-key="id"
        :scroll="{ x: 'max-content' }"
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        :row-selection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
        @change="handleChangeTable"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)">编辑</a>
            <a-divider type="vertical" />
            <a @click="batchDelete('single', record)">删除</a>
            <a-divider type="vertical" />
            <a @click="copyOrder(record)">复制订单</a>
          </template>
        </span>
        <template slot="boxInfoList" slot-scope="boxData">
          <div v-for="op in boxData" :key="op.id">
            <span class="fontStyle">JOB号：</span>{{ op.job_no }}
            <span class="fontStyle">箱信息：</span>{{ op.container_size }}{{ op.container_type }}
            <span class="fontStyle">存箱地：</span>{{ op.start_point_name }}
            <span class="fontStyle">流向点：</span>{{ op.end_point_name }}
            <br>
            <span class="fontStyle">数量：</span>{{ op.num }}
            <span class="fontStyle">已完成数量：</span>{{ op.finish_num }}
            <br>
            <span class="fontStyle">应收单价：</span>{{ op.charge_unit_price }}
            <span class="fontStyle">应收净值：</span>{{ op.charge_net_price }}
            <span class="fontStyle">应付单价：</span>{{ op.pay_unit_price }}
          </div>
        </template>
        <template slot="statusInfo" slot-scope="text, record">
          <a-tag :color="statusColor(record.status)">
            {{ text }}
          </a-tag>
        </template>
        <template slot="noDetail" slot-scope="text, record">
          <span class="fontStyle">EP号：</span>{{ record.ep_no }} <br>
          <!-- <span class="fontStyle">JOB号：</span>{{ record.job_no }} <br> -->
          <span class="fontStyle">拖运编号：</span>{{ record.business_no }} <br>
        </template>
        <template slot="customerDetail" slot-scope="text, record">
          <span class="fontStyle">调运人：</span>{{ record.dispatch_name }} <br>
          <span class="fontStyle">客户：</span>{{ record.customer_name }} <br>
          <span class="fontStyle">委托车队：</span>{{ record.supplier_name }} <br>
        </template>
      </a-table>

      <a-modal
        title="完成拖运箱量"
        :visible="finishNumVisible"
        :confirmLoading="finishNumLoading"
        :width="850"
        @cancel="handleFinishNumCancel"
        @ok="handleFinishNumSubmit"
      >
        <a-form-model
          layout="vertical"
          ref="finishNumForm"
          :model="finishNumForm"
        >
          <a-divider orientation="left">订单信息</a-divider>
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-model-item label="订单号">
                <a-input v-model="finishNumForm.serial_num" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="业务日期">
                <a-input v-model="finishNumForm.business_date" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="截港时间">
                <a-input v-model="finishNumForm.discharge_port_date" disabled />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="8">
              <a-form-model-item label="EP号">
                <a-input v-model="finishNumForm.ep_no" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="JOB号">
                <a-input v-model="finishNumForm.job_no" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="拖运编号">
                <a-input v-model="finishNumForm.business_no" disabled />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-divider orientation="left">箱信息</a-divider>
          <a-row :gutter="24">
            <a-col :span="24">
              <edit-table
                :columns="finishBoxColumns"
                :sourceData="finishBoxData"
                :operate="{ add: false, del: false, alldel: false }"
                :disabled="true"
                @getTableDate="getFinishTableDate"
              ></edit-table>
            </a-col>
          </a-row>
        </a-form-model>
      </a-modal>
    </a-card>
  </keep-alive>
</template>

<script>
import moment from 'moment'
import {
  pageItmsConOrderList,
  deleteItmsConOrder,
  confirmConShipmentStatus,
  cancelConShipmentStatus,
  listAllItmsOrderConShipment,
  queryItmsOrderConTenDayNotChargeOrPayOrderList,
  exportItmsOrderConShipmentSheet,
  queryItmsOrderConWeekAgoNoFinishOrderList,
  initItmsConCustomerInfo,
  copyItmsOrder,
  downloadConShipmentTemplate,
  importTemplateAddOrder
} from '@/api/itms'
import { downloadExcel } from '@/api/common'
import { EditTable } from '@/components'

export default {
  name: 'ItmsConsignForShipment',
  components: {
    EditTable
  },
  data() {
    return {
      queryParam: {},
      statusData: [
        { value: 0, name: '待完成' },
        { value: 1, name: '已完成' },
        { value: 2, name: '取消' }
      ],
      customerData: [],
      supplierData: [],
      dispatchNameData: [
        { value: '陈琦', name: '陈琦' },
        { value: '陈有来', name: '陈有来' },
        { value: '柴一帆', name: '柴一帆' },
        { value: '俞齐骏', name: '俞齐骏' },
        { value: '孙智慧', name: '孙智慧' },
        { value: 'TOM TAO', name: 'TOM TAO' },
        { value: '陈佳杰', name: '陈佳杰' },
        { value: '龚思健', name: '龚思健' }
      ],
      selectedRowKeys: [],
      selectedRows: [],
      columns: [
        {
          title: '订单号',
          dataIndex: 'serial_num'
        },
        {
          title: '业务日期',
          dataIndex: 'business_date',
          customRender: (text) => {
            return text ? moment(text).format('YYYY-MM-DD') : null
          },
          defaultSortOrder: 'descend',
          sorter: (a, b) => {
            if (a.business_date > b.business_date) {
              return 1
            } else if (a.business_date < b.business_date) {
              return -1
            } else {
              return 0
            }
          }
        },
        {
          title: '拖运状态',
          dataIndex: 'status_str',
          scopedSlots: { customRender: 'statusInfo' }
        },
        {
          title: '编号信息',
          scopedSlots: { customRender: 'noDetail' }
        },
        {
          title: '客户车队信息',
          scopedSlots: { customRender: 'customerDetail' }
        },
        {
          title: '箱信息',
          dataIndex: 'box_info_list',
          scopedSlots: { customRender: 'boxInfoList' }
        },
        {
          title: '应收',
          dataIndex: 'charge_sum_amount'
        },
        {
          title: '应收净值',
          dataIndex: 'charge_net_sum_amount'
        },
        {
          title: '应付',
          dataIndex: 'pay_sum_amount'
        },
        {
          title: '利润',
          dataIndex: 'profit'
        },
        {
          title: '截港时间',
          dataIndex: 'discharge_port_date',
          customRender: (text) => {
            return text ? moment(text).format('YYYY-MM-DD') : null
          }
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '操作',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 200,
        pageSizeOptions: ['10', '20', '50', '100', '200'],
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      alertMsg: null,
      boxMsg: null,
      noChargeOrPayOrderData: [],
      noFinishOrderData: [],
      finishNumVisible: false,
      finishNumLoading: false,
      finishNumForm: {
        con_shipment_id: null,
        serial_num: null,
        business_date: null,
        discharge_port_date: null,
        ep_no: null,
        job_no: null,
        business_no: null
      },
      finishBoxColumns: [
        {
          title: '箱型',
          dataIndex: 'container_type',
          extra: { type: 'text' }
        },
        {
          title: '箱尺寸',
          dataIndex: 'container_size',
          extra: { type: 'text' }
        },
        {
          title: '初始数量',
          dataIndex: 'num',
          extra: { type: 'text' }
        },
        {
          title: '已完成数量',
          dataIndex: 'finish_num',
          extra: { type: 'text' }
        },
        {
          title: '本次完成数量',
          dataIndex: 'last_finish_num',
          extra: { type: 'number' }
        }
      ],
      finishBoxData: []
    }
  },
  watch: {
    $route(newRoute) {
      if (newRoute.name === 'ItmsConsignForShipment' && this.queryParam) {
        newRoute.meta.title = '拖运业务'
        this.$nextTick(_ => {
          this.loadData()
        })
      }
    }
  },
  mounted() {
    this.loadData()
    this.loadTotal()
    this.loadTenDayNoChargeOrPayOrderData()
    this.loadWeekAgoNoFinishOrderData()
    initItmsConCustomerInfo().then(res => {
      this.customerData = res.CUSTOMER_LIST
      this.supplierData = res.SUPPLIER_LIST
    })
  },
  methods: {
    // 导入模板前触发
    beforeUpload(file) {
      const fileType = ['xls', 'xlsx']
      const suffix = file.name.split('.')[1]
      if (fileType.indexOf(suffix) === -1) {
        this.$notification['error']({
          message: '提示',
          description: '只能上传excel表格'
        })
        return false
      }
      if (file.size / 1024 / 1024 > 30) {
        this.$notification['error']({
          message: '提示',
          description: '上传文件不能超过30MB'
        })
        return false
      }
    },
    // 导入托运订单模板
    handleUpload(param) {
      const formData = new FormData()
      formData.append('file', param.file)
      importTemplateAddOrder(formData).then(res => {
        this.$notification['success']({
          message: '提示',
          description: '解析成功'
        })
        this.loadData()
        this.loadTotal()
      })
    },
    // 下载托运订单模板
    downloadTemplate() {
      this.$confirm({
        title: '确认提示',
        content: '确定要导出吗？',
        onOk: () => {
          downloadConShipmentTemplate().then(res => {
            if (res !== null) {
              const fileInfo = { fileName: res }
              downloadExcel(fileInfo).then(downRes => {
                const data = downRes.data
                let fileName = ''
                const contentDisposition = downRes.headers['content-disposition']
                if (contentDisposition) {
                  fileName = decodeURI(escape(contentDisposition.split('=')[1]))
                }
                const url = window.URL.createObjectURL(
                  new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                )
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              })
            }
          })
        }
      })
    },
    // 复制并创建一个新订单
    copyOrder(record) {
      this.$confirm({
        title: '提示',
        content: `确定要复制此订单${record.serial_num}吗?`,
        onOk: () => {
          copyItmsOrder({ 'id': record.id }).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '复制成功'
            })
          }).finally(_ => {
            this.loadData()
            this.loadTotal()
          })
        }
      })
    },
    exportSheet() {
      this.$confirm({
        title: '确认提示',
        content: '确定要导出吗？',
        onOk: () => {
          exportItmsOrderConShipmentSheet(this.queryParam).then(res => {
            if (res !== null) {
              const fileInfo = { fileName: res }
              downloadExcel(fileInfo).then(downRes => {
                const data = downRes.data
                let fileName = ''
                const contentDisposition = downRes.headers['content-disposition']
                if (contentDisposition) {
                  fileName = decodeURI(escape(contentDisposition.split('=')[1]))
                }
                const url = window.URL.createObjectURL(
                  new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                )
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              })
            }
          })
        }
      })
    },
    // 加载10天前已填写发票或出纳 还没收款或付款的记录
    loadTenDayNoChargeOrPayOrderData() {
      // 查询拖运业务类型的订单
      const reqParam = {
        'orderkinds': [5]
      }
      queryItmsOrderConTenDayNotChargeOrPayOrderList(reqParam).then(res => {
        this.noChargeOrPayOrderData = res
      })
    },
    // 加载7天前还没完成计划箱量的托运记录
    loadWeekAgoNoFinishOrderData() {
      queryItmsOrderConWeekAgoNoFinishOrderList().then(res => {
        this.noFinishOrderData = res
      })
    },
    // 拖运状态颜色切换
    statusColor(status) {
      switch (status) {
        case 0:
          return 'orange'
        case 1:
          return 'green'
        case 2:
          return 'red'
        case 3:
          return 'blue'
      }
    },
    getFinishTableDate(data) {
      this.finishBoxData = data
    },
    handleFinishNumCancel() {
      this.finishNumVisible = false
      this.finishNumLoading = false
      this.finishNumForm = {
        con_shipment_id: null,
        serial_num: null,
        business_date: null,
        discharge_port_date: null,
        ep_no: null,
        job_no: null,
        business_no: null
      }
      this.finishBoxData = []
      this.selectedRowKeys = []
      this.selectedRows = []
    },
    handleFinishNumSubmit() {
      this.finishNumLoading = true
      const reqParam = {
        'con_shipment_id': this.finishNumForm.con_shipment_id,
        'finish_box_data': this.finishBoxData
      }
      confirmConShipmentStatus(reqParam).then(res => {
        this.$notification['success']({
          message: '提示',
          description: '操作成功'
        })
      }).finally(_ => {
        this.loadData()
        this.loadTotal()
        this.handleFinishNumCancel()
      })
    },
    confirmConShipmentStatus() {
      if (this.selectedRowKeys.length !== 1) {
        this.$notification['warn']({
          message: '提示',
          description: '完成拖运计划一次只能选择一个订单'
        })
        return
      }

      let flag
      this.selectedRows.forEach(item => {
        if (item.status === 0 || item.status === 2 || item.status === 3) {
          // 待完成、部分完成、取消都可以提交完成计划
          flag = true
        }
      })

      if (flag) {
        this.finishNumVisible = true
        const currentObj = this.selectedRows[0]
        this.finishNumForm = {
          'con_shipment_id': currentObj.id,
          'serial_num': currentObj.serial_num,
          'business_date': currentObj.business_date,
          'discharge_port_date': currentObj.discharge_port_date,
          'ep_no': currentObj.ep_no,
          'job_no': currentObj.job_no,
          'business_no': currentObj.business_no
        }
        const boxData = []
        currentObj.box_info_list.forEach(item => {
          boxData.push({
            'box_id': item.id,
            'container_type': item.container_type,
            'container_size': item.container_size,
            'num': item.num,
            'finish_num': item.finish_num,
            'last_finish_num': item.num - item.finish_num
          })
        })
        this.finishBoxData = boxData
      } else {
        this.$notification['warn']({
          message: '提示',
          description: '只能操作待完成、部分完成或取消的拖运记录'
        })
      }
    },
    cancelConShipmentStatus() {
      const idList = []
      this.selectedRows.forEach(item => {
        if (item.status === 1) {
          idList.push(item.id)
        }
      })

      if (idList.length > 0) {
        cancelConShipmentStatus({ 'ids': idList }).then(res => {
          this.$notification['success']({
            message: '提示',
            description: '操作成功'
          })
        }).finally(_ => {
          this.loadData()
          this.selectedRowKeys = []
          this.selectedRows = []
        })
      } else {
        this.$notification['warn']({
          message: '提示',
          description: '只能取消拖运状态为已完成的记录'
        })
      }
    },
    businessDateChange(date, dateStr) {
      if (dateStr[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['start_date'] = startDate
        this.queryParam['end_date'] = endDate
      } else {
        this.queryParam['start_date'] = null
        this.queryParam['end_date'] = null
      }
    },
    loadData() {
      pageItmsConOrderList(this.queryParam).then(res => {
        this.pagination.total = res.total
        this.data = res.list
      })
    },
    loadTotal() {
      listAllItmsOrderConShipment(this.queryParam).then(res => {
        this.alertMsg = res.alertMsg
        this.boxMsg = res.boxMsg
      })
    },
    queryList() {
      this.pagination.current = 1
      this.pagination.pageSize = 200
      this.queryParam['page'] = 1
      this.queryParam['page_size'] = 200
      this.loadData()
      this.loadTotal()
    },
    handleChangeTable(page) {
      this.pagination.current = page.current
      this.pagination.pageSize = page.pageSize
      this.queryParam['page'] = page.current
      this.queryParam['page_size'] = page.pageSize
      this.loadData()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handlerCreate() {
      this.$router.replace({
        name: 'ItmsConsignForShipmentCUR',
        params: {
          id: 'create'
        }
      })
    },
    handleEdit(record) {
      this.$router.replace({
        name: 'ItmsConsignForShipmentCUR',
        params: record
      })
    },
    batchDelete(type, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let ids = []
          if (type === 'all') {
            ids = this.selectedRows.map(item => item.id)
          } else {
            ids = [ record.id ]
          }
          deleteItmsConOrder({ 'ids': ids }).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功'
            })
            this.loadData()
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.fontStyle {
  font-weight: bold;
  color: #FAAD14;
}
</style>
